<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useLayoutStore } from '@/stores/layout'
import { storeToRefs } from 'pinia'
import { useWindowSize } from '@vueuse/core'
import { useProfileStore } from '@/stores/profile'
import { useRoute } from 'vue-router'
import axios from 'axios'

const layoutStore = useLayoutStore()
const { drawer } = storeToRefs(layoutStore)
const nav = ref(null)
const rail = ref(false)
const { width } = useWindowSize()
const { me } = useProfileStore()
const route = useRoute()
const alias = computed({
  get() {
    return drawer.value || width.value > 1024
  },
  set(value) {
    drawer.value = value
  }
})
const railAlias = computed(() => {
  return (rail.value || width.value < 1280) && width.value > 1024
})
function signout() {
  me.profile = null
  axios.get('/auth/logout')
}
function goToDiscord() {
  window.open(import.meta.env.VITE_DISCORD_INVITE, '_blank')
}
function goHelpCenter() {
  window.open(import.meta.env.VITE_DISCORD_HELP, '_blank')
}
</script>
<template>
  <v-navigation-drawer
    v-model="alias"
    ref="nav"
    :permanent="width > 1024"
    :temporary="width <= 1024"
    :rail="railAlias"
    border="0"
    class="rounded-e-xl"
    color="var(--md-sys-color-secondary-container)"
    app
    expand-on-hover
  >
    <div class="flex justify-between items-center p-4 min-h-[72px] on-secondary-container-text">
      <p class="logo" :class="{ 'long-logo': railAlias }" style="font-size: 24px">ogoto</p>
      <p v-if="railAlias" class="logo" :class="{ 'short-logo': railAlias }" style="font-size: 24px">
        og
      </p>
      <v-btn
        v-show="width >= 1280"
        :class="{ pin: railAlias }"
        size="small"
        variant="text"
        icon="mdi-pin"
        :color="!rail ? 'primary' : 'var(--md-ref-palette-neutral60)'"
        @click.stop="rail = !rail"
      ></v-btn>
    </div>
    <v-list nav>
      <v-list-item
        :active="route.name === 'app'"
        prepend-icon="mdi-home-variant-outline"
        title="홈"
        rounded="xl"
        value="app"
        to="/"
        base-color="var(--md-sys-color-on-secondary-container)"
      ></v-list-item>
      <v-list-item
        :active="route.name === 'explore'"
        prepend-icon="mdi-compass-outline"
        title="클럽 탐색"
        rounded="xl"
        value="explore"
        base-color="var(--md-sys-color-on-secondary-container)"
        to="/explore"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-help-circle-outline"
        title="고객 센터"
        rounded="xl"
        value="help"
        base-color="var(--md-sys-color-on-secondary-container)"
        @click="goHelpCenter"
      ></v-list-item>
    </v-list>
    <template #append>
      <v-list nav>
        <v-list-item
          rounded="lg"
          variant="tonal"
          :prepend-avatar="me.profile?.images?.[0]"
          :title="me.profile?.username"
        >
          <template v-slot:append>
            <v-menu location="end">
              <template v-slot:activator="{ props }">
                <v-btn icon variant="plain" v-bind="props">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list rounded="lg" density="compact">
                <v-list-item
                  rounded="md"
                  append-icon="mdi-open-in-new"
                  value="discord"
                  @click="goToDiscord"
                >
                  <v-list-item-title>Go to Discord</v-list-item-title>
                </v-list-item>
                <v-list-item rounded="md" append-icon="mdi-logout" value="logout" @click="signout">
                  <v-list-item-title>Sign Out</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.long-logo {
  display: none;
}
.pin {
  display: none;
}
.v-navigation-drawer--is-hovering {
  .long-logo {
    display: block;
  }
  .short-logo {
    display: none;
  }
  .pin {
    display: inherit;
  }
  :deep(.v-list-item--variant-tonal .v-list-item__underlay) {
    background: currentColor !important;
  }
}
.v-navigation-drawer--rail {
  :deep(.v-list-item--variant-tonal .v-list-item__underlay) {
    background: transparent;
  }
}
:deep(.v-list-item--variant-tonal) {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
</style>
