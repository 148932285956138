<script setup lang="ts">
import { useProfileStore } from '@/stores/profile'
import CoreView from '@/components/core/CoreView.vue'
import CoreDrawer from '@/components/core/CoreDrawer.vue'
import AppHeader from '@/components/core/AppHeader.vue'
import MainBanner from '@/components/home/MainBanner.vue'
import WhyUse from '@/components/home/WhyUse.vue'
import WhatUse from '@/components/home/WhatUse.vue'
import WhyMake from '@/components/home/WhyMake.vue'
import { useRouter } from 'vue-router'
import { useAuth } from '@/composables/useFirebase'
import axios from 'axios'
import { notifyError } from '@/lib/notify'
import { onMounted } from 'vue'
import AppFooter from '@/components/core/AppFooter.vue'
import HowTo from '@/components/home/HowTo.vue'

const profileStore = useProfileStore()
const { me } = profileStore
const router = useRouter()
const { signIn, getRedirect } = useAuth()

onMounted(() => {
  checkoutAuth()
})

async function goLogin() {
  const { user } = await signIn()
  if (!user) return
  afterSignin(user)
}

async function checkoutAuth() {
  const { user } = await getRedirect()
  if (!user) return
  await afterSignin(user)
}
async function afterSignin(user: any) {
  console.log(user)
  try {
    const res = await axios.get('/dinners/email/' + user.email)
    console.log(res.data)
    if (res.data) {
      me.profile = res.data
    } else {
      router.push('/signup')
    }
  } catch (e) {
    notifyError(e)
  }
}
</script>

<template>
  <div v-if="!me.profile">
    <div class="home overflow-x-hidden">
      <header class="px-5 py-4 flex justify-between items-center z-50">
        <p class="logo">ogoto</p>
        <div>
          <router-link to="/login">
            <v-btn variant="text" class="label-medium mr-4">로그인</v-btn>
          </router-link>
          <router-link to="/dinner-form">
            <v-btn variant="flat" class="label-medium">가입하기</v-btn>
          </router-link>
        </div>
      </header>
      <main-banner />
      <how-to />
      <app-footer />
      <!--      <v-btn class="mt-8 !block" size="x-large" width="253" @click="goLogin">시작하기</v-btn>-->
    </div>
  </div>
  <v-layout v-else>
    <core-drawer />
    <app-header />
    <core-view />
  </v-layout>
</template>
<style scoped lang="scss">
.home {
  background-color: #f5f1e9;
  padding-top: 54px;
  header {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    height: 54px;
  }
}
.logo {
  font-size: 20px;
}
</style>
