<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useRes } from '@/composables/useRes'

const props = defineProps<{
  src: string
  v: number
}>()

const { y } = useWindowScroll()
const target = ref<HTMLElement | null>(null)
// onMounted(() => {
//   nextTick(() => {
//     console.log(target.value?.getBoundingClientRect(), window.innerHeight)
//   })
// })
const offsetY = computed(() => {
  if (!target.value) return 0
  if (y.value < 2) return 0
  // console.log(target.value?.getBoundingClientRect(), window.innerHeight, target.value?.offsetTop)
  if (window.innerHeight > (target.value?.offsetTop ?? 0)) {
    return props.v * y.value
  } else {
    return Math.max(0, window.innerHeight - target.value?.getBoundingClientRect().top) * props.v
  }
})
</script>

<template>
  <div class="parallax-img" ref="target">
    <img
      :src="useRes(src)"
      alt="img"
      :style="{ transform: 'translateY(' + -1 * offsetY + 'px)' }"
    />
  </div>
</template>

<style scoped lang="scss">
.parallax-img {
  img {
    transition: transform 10ms ease-out;
  }
}
</style>
