<script setup lang="ts">
const list = [
  {
    title: '저녁 식사 신청하기',
    content:
      '신청하기 선택하여 가입을 진행해주세요.\n최상의 매칭을 위해 간단한 설문으로 취향과 성격을 파악합니다.',
    color: '#f9bbcb'
  },
  {
    title: '새로운 사람 5명 매칭',
    content: '일정을 선택하고 신청이 완료되면\n당신에게 꼭 맞는 사람들을 매칭해드립니다.',
    color: '#B7B12A'
  },
  {
    title: '식당 예약은 저희에게 맡겨주세요',
    content: '저희가 엄선한 식당을 예약하고 장소 정보를 알려드려요.',
    color: '#FFF856'
  },
  {
    title: '약속 장소에 가서 즐기기',
    content:
      '편안한 마음으로 약속된 시간과 장소에 들리셔서\n멋진 새로운 사람들과 맛있는 저녁을 드시면 됩니다.',
    color: '#FF562C'
  },
  {
    title: '모임 리뷰 & 인연 이어가기',
    content:
      '모임이 어땠는지 리뷰하고\n또 마음이 통하는 친구가 생겼다면 저희에게 알려주세요. 상대도 서로 같은 마음이라면 연락처를 알려드려요.',
    color: '#92DAFF'
  }
]
const list2 = [
  {
    title: '식당은 어떻게 고르나요?',
    content: '내 현재 주머니 사정에 맞게 선택 범위를 선택할 수 있습니다.',
    color: '#F9BBCB'
  },
  {
    title: '식성을 \n' + '고려한 픽!',
    content: '모임의 모두가 만족할 수 있도록 식성을 고려하여 식당을 고릅니다!',
    color: '#B7B12A'
  },
  {
    title: '맛, 분위기\n모두 최고인 곳만',
    content: '맛있고 모여서 이야기 하기 좋은 맛과 멋 식당만 고르고 골라서 잡아드립니다!',
    color: '#FFF856'
  }
]
</script>

<template>
  <div class="px-10 pb-10">
    <h3 class="headline-medium mb-10">어떻게 하는 건가요?</h3>
    <div class="box pl-10" v-for="(item, i) in list" :key="i">
      <div class="flex items-center z-10">
        <div class="circle rounded-full" :style="{ 'background-color': item.color }">
          <p>{{ i + 1 }}</p>
        </div>
        <div class="line w-[279px] h-[5px]" :style="{ 'background-color': item.color }"></div>
      </div>
      <div class="content-box ml-[61px]">
        <h4 class="title-large mb-6">{{ item.title }}</h4>
        <p class="title-medium">{{ item.content }}</p>
      </div>
    </div>
    <div class="flex justify-center mt-10">
      <router-link to="/dinner-form">
        <v-btn variant="flat" size="x-large" width="253" class="label-medium">신청하기</v-btn>
      </router-link>
    </div>
  </div>
  <div class="mt-[180px]">
    <h3 class="headline-medium mb-19">식당은 어떻게 고르나요?</h3>
    <div class="flex">
      <div
        class="box-2 px-16 py-24 flex justify-center flex-column items-center text-center gap-[72px]"
        v-for="(item, i) in list2"
        :key="i"
        :style="{ 'background-color': item.color }"
      >
        <p class="headline-medium">{{ i + 1 }}</p>
        <p class="headline-medium">{{ item.title }}</p>
        <p class="headline-small">{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.box {
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 122px;
    aspect-ratio: 1;
    font-size: 48px;
    font-weight: 400;
  }
  .content-box {
    border-left: 5px solid black;
    margin-top: -50px;
    padding-left: 143px;
    padding-bottom: 100px;
  }
}
.box:last-child {
  .content-box {
    border-left: none;
    padding-bottom: 0;
  }
}
</style>
