<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout'
import { storeToRefs } from 'pinia'

const layoutStore = useLayoutStore()
const { drawer } = storeToRefs(layoutStore)
</script>

<template>
  <v-app-bar class="app-bar" density="compact" flat>
    <v-app-bar-nav-icon
      class="lg:!hidden"
      variant="text"
      @click.stop="drawer = !drawer"
    ></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<style scoped lang="scss"></style>
