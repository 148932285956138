<script setup lang="ts">
import AnimeText from '@/components/global/AnimeText.vue'
import { useRouter } from 'vue-router'
import ParallaxImage from '@/components/global/ParallaxImage.vue'
import { useRes } from '@/composables/useRes'
import { useDisplay } from 'vuetify'
import { useAuth } from '@/composables/useFirebase'

const router = useRouter()
const { smAndUp } = useDisplay()
const { signIn } = useAuth()
function goLogin() {
  router.push('/dinner-form')
}
</script>

<template>
  <div class="banner flex justify-center h-[1000px] relative overflow-x-hidden">
    <div class="banner-text p-10">
      <anime-text
        class="headline mb-4 sm:mb-12 on-surface-text w-[668px]"
        :text="'매주, 새로운 사람 5명과 만나 저녁 먹어요.'"
        :gap="smAndUp ? 30 : 8"
      />
      <div class="relative inline-block w-[668px] max-w-full">
        <p class="headline-s mb-4 on-surface-text">
          내 일상의 경계를 확장시켜 줄 설레는 저녁 약속. 지금 신청하고 나와 잘 맞는 새로운 사람
          5명을 만나보세요.
        </p>
        <img class="a1 z-30" :src="useRes('a1.png')" alt="a1" />
      </div>
      <parallax-image class="a2" src="a2.jpg" :v="1" />
      <parallax-image class="a3" src="a3.jpg" :v="0.3" />
      <v-btn class="mt-8 !block" size="x-large" width="253" @click="goLogin">신청하기</v-btn>
      <div class="mt-8 flex flex-col gap-14 sm:hidden" style="margin-right: -40px">
        <img class="a3-s self-end" :src="useRes('a3.jpg')" />
        <img class="a2-s" :src="useRes('a2.jpg')" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/assets/mixins';
p {
  position: relative;
  z-index: 3;
}
.banner-text {
  width: 100%;
  max-width: 1200px;
}
.headline {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  z-index: 3;
  position: relative;
  text-shadow: 0 0 1px white;
}
.headline-s {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}
.a2,
.a3 {
  display: none;
}
.a1 {
  position: absolute;
  width: 24px;
  left: 100px;
  bottom: -15px;
}
.a2 {
  position: absolute;
  width: 294px;
  right: 20vw;
  top: 550px;
  z-index: 1;
}
.a3 {
  position: absolute;
  right: 6vw;
  top: 100px;
  z-index: 0;
  width: 339px;
}
.a2-s {
  width: 238px;
}
.a3-s {
  width: 322px;
}
@include sm {
  .headline {
    font-size: 64px;
  }
  .headline-s {
    font-size: 40px;
  }
  .a1 {
    width: 68px;
    right: 0;
    left: auto;
    bottom: -15px;
  }

  .a2 {
    display: block;
    right: 20vw;
    bottom: 0;
  }
  .a3 {
    display: block;
    position: absolute;
  }
}
</style>
